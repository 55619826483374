import React, { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';

import { Text } from 'reaxl';
import { sendClick } from 'reaxl-analytics';
import { myWalletMonthlyPaymentFilterClick } from 'reaxl-analytics-handlers';
import { Tip } from 'reaxl-molecules';
import { WalletSymbol } from 'reaxl-wallet';

import { paymentsDuck } from '@/ducks';

import MyWalletToggleContainer from './MyWalletToggleContainer';

const DEFAULT_MAX_PAYMENT = 600;

export function MyWalletMonthlyPaymentFilterContainer() {
    const dispatch = useDispatch();
    const monthlyBudget = useSelector((state) => _get(paymentsDuck.selectors.getPaymentsInfo(state), 'monthlyBudget', 0));
    const showPaymentInFilter = useSelector((state) => paymentsDuck.selectors.getMyWalletInteraction(state, 'showPaymentInFilter'));
    const showPostEngagementState = showPaymentInFilter && monthlyBudget;

    const handleMyWalletFilterClick = useCallback(async (event) => {

        if (showPostEngagementState) {
            sendClick(myWalletMonthlyPaymentFilterClick, event, {
                cmp: 'upd_max_pymt_set',
                eventResult: 'text-link::open::update-payment-settings::max',
                co_txt_url: 'Update Max Payment Settings',
            });
        } else {
            sendClick(myWalletMonthlyPaymentFilterClick, event, {
                cmp: 'ent_amt',
                eventResult: 'text-link::open::enter-amount::min',
                co_txt_url: 'Enter Amount',
            });
        }

        dispatch(paymentsDuck.creators.updateMyWalletInteractions({
            paymentEmphasis: true,
            disablePaymentPopover: true,
            budgetSummaryType: 'monthly',
            focusBudgetInput: true,
            showPaymentInFilter: true,
        }));

        dispatch(paymentsDuck.creators.updateMyWallet({
            displayModal: true,
            selectedPanelName: 'budget',
            activatedMyWalletElement: event.target,
        }));

    }, [dispatch, showPostEngagementState]);

    const toggleAnalyticsData = {
        eventResult: 'cta::open::update-payment-settings',
    };

    const renderWalletMonthlyPaymentHeadline = (
        <div style={showPostEngagementState ? {} : { fontStyle: 'italic' }}>
            {!showPostEngagementState && (
                <Text size={100}>
                    Ex.&nbsp;
                </Text>
            )}
            <WalletSymbol
                size={400}
                symbol="$"
                componentClass="sup"
                color="default"
            />
            <Text
                size={600}
                weight="bold"
            >
                {showPostEngagementState ? monthlyBudget || '0' : DEFAULT_MAX_PAYMENT}
            </Text>
            <Text size={300}>
                /mo.
            </Text>
        </div>
    );

    const tipBody = (
        <div className="text-center">
            <div className="content-preview-category">
                <label
                    className="control-label"
                    htmlFor="price-filter-wallet-toggle"
                >
                    Max Payment
                </label>
            </div>
            <div className="content-preview-headline">
                {renderWalletMonthlyPaymentHeadline}
            </div>
            <div>
                <Text
                    className="text-link link-undecorated"
                    size={300}
                >
                    {showPostEngagementState ? 'Update payment settings' : 'Personalize Payment'}
                </Text>
            </div>
        </div>
    );

    return (
        <div data-cmp="MyWalletFilter">
            <Tip
                body={tipBody}
                className="margin-vertical-4 rounded"
                borderColor="info-200"
                backgroundColor="info-100"
                onClick={handleMyWalletFilterClick}
            />
            <div className="display-flex align-items-center justify-content-center padding-vertical-2">
                <MyWalletToggleContainer
                    id="price-filter-wallet-toggle"
                    label="Show payments on listings"
                    toggleAnalyticsData={toggleAnalyticsData}
                />
            </div>
        </div>
    );
}

export default React.memo(MyWalletMonthlyPaymentFilterContainer, _isEqual);
